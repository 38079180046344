import React, { useRef } from "react";
import "./pdf.css";

export default function Pdf({ title, src }) {
    const iframeRef = useRef(null);

    const enterFullScreen = () => {
        const iframe = iframeRef.current;

        if (iframe) {
            if (iframe.requestFullscreen) {
                iframe.requestFullscreen();
            } else if (iframe.mozRequestFullScreen) {
                iframe.mozRequestFullScreen();
            } else if (iframe.webkitRequestFullscreen) {
                iframe.webkitRequestFullscreen();
            } else if (iframe.msRequestFullscreen) {
                iframe.msRequestFullscreen();
            }
        }
    };

    const exitFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    };

    return (
        <div className="container" style={{ height: "100vh", width: "100%" }}>
            <h1 className="title">{title}</h1>
            <button onClick={enterFullScreen}>Enter Full Screen</button>
            <iframe
                ref={iframeRef}
                src={src}
                width="100%"
                height="80%"
                allow="autodisplay"
                title="pdf"
                style={{ border: "none" }}
            />
        </div>
    );
}
