import React, { useState } from "react";
import "./App.css";
import Menu from "./components/Menu";
import Pdf from "./components/Pdf";
const presentation = [
    {
        title: "React Inroduction",
        src: "https://braintop.io/react-presents/1-react-introduction.pdf",
    },
    {
        title: "React SetUP",
        src: "https://braintop.io/react-presents/2-react-setup.pdf",
    },
    {
        title: "React First Project",
        src: "https://braintop.io/react-presents/3-react-first-project.pdf",
    },
    {
        title: "React JSX",
        src: "https://braintop.io/react-presents/4-react-jsx.pdf",
    },
    {
        title: "React virtual DOM",
        src: "https://braintop.io/react-presents/5-react-virtual-dom.pdf",
    },
    {
        title: "React Components and Props",
        src: "https://braintop.io/react-presents/6-compnents-and-props.pdf",
    },
    {
        title: "States",
        src: "https://braintop.io/react-presents/7-states.pdf",
    },
    {
        title: "Events",
        src: "https://braintop.io/react-presents/8-events.pdf",
    },
    {
        title: "Conditional Rendering",
        src: "https://braintop.io/react-presents/9-conditinal-rendering.pdf",
    },
];
const App = () => {
    const [present, setPresent] = useState(presentation[0]);
    function handlePresnt(index) {
        setPresent(presentation[index]);
        console.log(index);
    }
    return (
        <div className="container">
            <div className="menu">
                <Menu handlePresnt={handlePresnt} />
            </div>
            <Pdf title={present.title} src={present.src} />
        </div>
    );
};

export default App;
