import React from "react";
import "./Menu.css";
export default function Menu({ handlePresnt }) {
    return (
        <div>
            <div>
                <button onClick={() => handlePresnt(0)}>
                    1. React Introduction
                </button>
                <button onClick={() => handlePresnt(1)}>2. React Set Up</button>
                <button onClick={() => handlePresnt(2)}>
                    3. React First Project
                </button>
                <button onClick={() => handlePresnt(3)}>4. React JSX</button>
                <button onClick={() => handlePresnt(4)}>
                    5. React Virtual DOM
                </button>
                <button onClick={() => handlePresnt(5)}>
                    6. React Components and Props
                </button>
            </div>
            <div>
                <button onClick={() => handlePresnt(6)}>7. States</button>
                <button onClick={() => handlePresnt(7)}>8. Events</button>
                <button onClick={() => handlePresnt(8)}>
                    9. Conditional Rendering
                </button>
            </div>
        </div>
    );
}
